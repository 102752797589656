'use strict';

angular.module('cpformplastApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('main', {
        url: '/',
        template: '',
        controller: function(Auth, $state) {

          Auth.getCurrentUser(function(user, err) {
            if (!user || err) {
              $state.go('login');
            }
            else if(user.role === 'admin'){
              $state.go('dashboard');
            }
            else if(user.role === 'shipper'){
              $state.go('shipping/exterior');
            }
            else if(user.role === 'productionManagerIn' || user.role === 'productionManagerOut'){
              $state.go('production');
            }
            else if(user.role === 'receptionist'){
              $state.go('dashboard');
            }
            else{
              $state.go('login');
            }
          });

        }
      });
  });
